import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import '@iblai/ibl-web-react-common/index.css';
import { getCourseMeta } from '../hooks/utils/studio';
import { postInvite } from '../hooks/utils/invitations';
import { useTranslation } from 'react-i18next';
import CredentialsTable from './content/Tables/CredentialsTable/CredentialsTable';
import SkillPointsTable from './content/Tables/SkillPointsTable/SkillPointsTable';
import AssignSkill from './content/SkillsCoursesViewContent/modals/AssignSkill';
import CreateCredential from './content/SkillsCoursesViewContent/modals/CreateCredential';

const CourseView = () => {
  const { courseID } = useParams();
  const iframeRef = useRef(null);
  const [pageTitle, setPageTitle] = useState('');
  const [iframeRandom, setIframeRandom] = useState(0);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [courseName, setCourseName] = useState('');
  const [isInviteActive, setIsInviteActive] = useState(false);
  const [userNameOrEmail, setUserNameOrEmail] = useState('');
  const [callBackMsgStatus, setCallBackMsgStatus] = useState('');
  const [callBackMsg, setCallBackMsg] = useState('');

  const handleInviteBtnClick = () => {
    setIsInviteActive(!isInviteActive);
  };
  const studioURL = `${process.env.REACT_APP_IBL_STUDIO_URL}course/${courseID}`;
  const viewLiveURL = `${process.env.REACT_APP_IBL_SKILLS_IFRAME_WIDGET_ORIGIN_URL}/courses/${courseID}`;
  const sendMessage = () => {
    return setTimeout(() => {
      try {
          iframeRef.current.contentWindow.postMessage(
            { reason: 'PROFILE_DISPLAY', message: { courseID } },
            '*'
          );
          iframeRef.current.contentWindow.postMessage(
            {
              reason: 'TOKEN',
              message: { token: localStorage.getItem('token') },
            },
            '*'
          );
      } catch(e) {
        console.error('Error sending message to iframe:', e);
      }
    
    }, 1000);
  };

  const clearForm = () => {
    setUserNameOrEmail('');
    setCallBackMsg('');
    setCallBackMsgStatus('');
  };

  const handleSubmitInvite = () => {
    let data = {
      userNameOrEmail: userNameOrEmail,
      type: 'ibl-dm.public.dl_catalog_app_course',
      id: courseID,
    };
    postInvite(data, (inviteData) => {
      if (inviteData) {
        setCallBackMsg('Invitation sent successfully');
        setCallBackMsgStatus('success');
      } else {
        setCallBackMsg('Invitation failed');
        setCallBackMsgStatus('error');
      }
    });
  };

  useEffect(() => {
    getCourseMeta(courseID, (data) => {
      setCourseName(data.display_name);
    });
    let timeoutIds = [];
    if (iframeLoaded) {
      sendMessage();
    } else {
      try {
        iframeRef.current.style.visibility = 'hidden';
      } catch (e) {
        console.error('Error setting iframe visibility:', e);
      }
      iframeRef.current.addEventListener('load', () => {
        setIframeLoaded(true);
        timeoutIds.push(
          setTimeout(() => {
            try {
              iframeRef.current.style.visibility = '';
            } catch (e) {
              console.error('Error setting iframe visibility:', e);
            }
          }, 1000)
        );
        timeoutIds.push(sendMessage());
      });
    }
    setIframeRandom(iframeRandom + 1);
    window.addEventListener('message', (event) => {
      if (event.data.reason === 'PROFILE_DISPLAY') {
        const page = event.data.message.page;
        setPageTitle(`${page}`);
      }
      if (event.data.reason === 'IFRAME_DIMENSION') {
        // iframeRef.current.style.height = `${event.data.message.height + 100}px`;
      }
    });
    return () => {
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, [courseID]);
  const { t } = useTranslation();

  // credentials and skill points logic

  const [isSkillPopupOpen, setIsSkillPopupOpen] = useState(false);
  const [createCredentialPopupOpen, setCreateCredentialPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshSkillPoints, setRefreshSkillPoints] = useState(false);
  const [refreshCredential, setRefreshCredential] = useState(false);
  const [notification, setNotification] = useState({
      success: null,
      msg: null,
    });

    const [credentialData, setCredentialData] = useState({
      is_update : false,
      entityId: '',
      issuer: '',
      name: '',
      description: '',
      iconImage: '',
      thumbnailImage: '',
      backgroundImage: '',
      credentialType: '',
      criteriaNarrative: '',
      criteriaUrl: '',
      courses: [courseID],
      html_template: '',
      ccs_template: '',
    });

    const [selectedSkill, setSelectedSkill] = useState(null);
    const [skillPoints, setSkillPoints] = useState(0);
    const [isSkillUpdate, setIsSkillUpdate] = useState(false);


  

  return (
    <>
      <div
        className={`popup_div-nm_popup invite-popup ${isInviteActive ? 'active' : ''}`}
      >
        <div className="popup_cont-sk">
          <div className="hf_popup">
            <div className="div-block-147 new_msg">
              <div className="text-block-135 mg-0">
                Invite User
                <strong>
                  <br />
                </strong>
              </div>
            </div>
            <div className="div-block-228">
              <div className="form-block-7 w-form">
                <form
                  data-name="Email Form 5"
                  data-wf-element-id="1652ea72-9a5b-2d7f-eb57-581687993f77"
                  data-wf-page-id="657aff678982edbdb39ddab6"
                  id="email-form-5"
                  method="get"
                  name="email-form-5"
                >
                  <label className="text-block-139-bd-f-soc" htmlfor="name-2">
                    {t('user', { defaultValue: 'User' })} *
                  </label>
                  <input
                    className="text-field-3 w-input"
                    data-name="Name 2"
                    id="name-2"
                    maxLength={256}
                    name="name-2"
                    placeholder="Enter a username or an email"
                    type="text"
                    value={userNameOrEmail}
                    onChange={(e) => setUserNameOrEmail(e.target.value)}
                  />
                </form>
              </div>
              <div className={`callback-msg ${callBackMsgStatus}`}>
                {callBackMsg}
              </div>
            </div>

            <div className="div-block-147 new_msg">
              <div className="div-block-264 f-w">
                <div
                  className="div-block-207 first active follow-user alt-btn cancel-invite"
                  onClick={() => {
                    setIsInviteActive(false);
                    clearForm();
                  }}
                >
                  <div className="text-block-111 red-text cf">
                    {t('cancel', { defaultValue: 'Cancel' })}
                  </div>
                </div>
                <div
                  className="div-block-207 first active follow-user redeem-btn mg-l16"
                  onClick={handleSubmitInvite}
                >
                  <div className="text-block-111 red-text cf">
                    {t('invite', { defaultValue: 'Invite' })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div-block-183">
        <div className="div-block-205">
          <div className="div-block-206"></div>
          <div
            className="text-block-113"
            style={{ textTransform: 'capitalize' }}
          >
            COURSE <span> | {courseName}</span>
          </div>
        </div>
        <div className="div-block-308">
          <Link
            to="instructor"
            style={{ display: 'none' }}
            target=""
            prop-events-value-onclick="handleAddNewCourseClick"
            prop-events-names="onClick"
            href="#"
            className="link-block-22 w-inline-block"
          >
            <div className="div-block-207 first active follow-user alt-btn">
              <div className="text-block-111">Instructor</div>
            </div>
          </Link>
          <div
            className="div-block-207 first active follow-user invite-btn alt-btn"
            onClick={handleInviteBtnClick}
          >
            <div className="text-block-111">Invite</div>
          </div>
          <Link
            to={studioURL}
            target="_blank"
            prop-events-value-onclick="handleAddNewCourseClick"
            prop-events-names="onClick"
            href="#"
            className="link-block-22 w-inline-block"
          >
            <div className="div-block-207 first active follow-user alt-btn">
              <div className="text-block-111">Edit</div>
            </div>
          </Link>
          <Link
            to="#"
            prop-events-value-onclick="handleAddNewCourseClick"
            prop-events-names="onClick"
            onClick={() => setCreateCredentialPopupOpen(true)}
            className="link-block-22 w-inline-block"
          >
            <div className="div-block-207 first active follow-user alt-btn">
              <div className="text-block-111">Create Credential</div>
            </div>
          </Link>

          <Link
            to="#"
            prop-events-value-onclick="handleAddNewCourseClick"
            prop-events-names="onClick"
            onClick={() => setIsSkillPopupOpen(true)}
            className="link-block-22 w-inline-block"
          >
            <div className="div-block-207 first active follow-user alt-btn">
              <div className="text-block-111">Assign Skill Points</div>
            </div>
          </Link>

          <Link
            to={viewLiveURL}
            target="_blank"
            prop-events-names="onClick"
            href="#"
            className="link-block-22 w-inline-block"
          >
            <div className="div-block-207 first active follow-user">
              <div className="text-block-111">View Live</div>
            </div>
          </Link>
        </div>
      </div>
      <iframe
        title={`Course for ${courseID}`}
        // key={iframeRandom}
        style={{ height: '100vh' }}
        ref={iframeRef}
        src={`${process.env.REACT_APP_IBL_SKILLS_IFRAME_WIDGET_ORIGIN_URL}/courses/${courseID}`}
      ></iframe>

      <CredentialsTable
        refreshCredential={refreshCredential}
        courseID={courseID}
        credentialData={credentialData}
        setCredentialData={setCredentialData}
        setCreateCredentialPopupOpen={setCreateCredentialPopupOpen}
      />
      <SkillPointsTable
        setIsSkillUpdate={setIsSkillUpdate}
        setSelectedSkill={setSelectedSkill}
        setIsSkillPopupOpen={setIsSkillPopupOpen}
        setPoints={setSkillPoints}
        courseId={courseID}
        refreshSkillPoints={refreshSkillPoints}
      />

      {isSkillPopupOpen && (
        <AssignSkill
          setIsSkillUpdate={setIsSkillUpdate}
          isSkillUpdate={isSkillUpdate}
          notification={notification}
          setNotification={setNotification}
          loading={loading}
          selectedSkill={selectedSkill}
          setSelectedSkill={setSelectedSkill}
          courseID={courseID}
          setLoading={setLoading}
          skillPoints={skillPoints}
          setSkillPoints={setSkillPoints}
          setRefreshSkillPoints={setRefreshSkillPoints}
          setIsSkillPopupOpen={setIsSkillPopupOpen}
        />
      )}

      {createCredentialPopupOpen && (
        <CreateCredential
          credentialData={credentialData}
          setCredentialData={setCredentialData}
          setRefreshCredential={setRefreshCredential}
          notification={notification}
          setNotification={setNotification}
          loading={loading}
          courseID={courseID}
          setLoading={setLoading}
          setCreateCredentialPopupOpen={setCreateCredentialPopupOpen}
        />
      )}
    </>
  );
};

export default CourseView;
