import {useEffect, useState} from 'react';
import {getLicensingUserGroupList} from "./utils/licensing";
import {useDebounce} from "./useDebounce";  // Replace with the correct path

const useLicensingGroup = ({search="", page=1, size=10, refresher=null}) => {
    const [groups, setGroups] = useState([]);
    const [groupLoading, setGroupLoading] = useState(false);
    const [pagination, setPagination] = useState({
        totalItems:0,
        currentPage:1,
        totalPages:0,
        pageSize:0
    });


    const handleGroupListRetrieval = (data) => {
        setGroups(Array.isArray(data?.results) ? data.results : [])
        setPagination({
            totalItems:  data?.count,
            currentPage: page,
            totalPages: Math.ceil(data?.count / size),
            pageSize: size,
        });
        setGroupLoading(false)
    }

    useDebounce(()=>{
        setGroupLoading(true)
        getLicensingUserGroupList( {
            page_size:size,
            page,
            ...(!!search ? {
                query:search
            } : {})
        }, handleGroupListRetrieval, handleGroupListRetrieval)
    }, 200, [search, page, size, refresher])

    return {
        groups,
        groupLoading,
        pagination
    }


};

export default useLicensingGroup;
