import { useEffect, useRef, useState } from 'react';
import {getAdminProgramsAPI, getStudioURL, getTableSizes} from './helpers';
import {getCourseAPI} from "../views/content/SkillsCoursesViewContent/utils";
import {api, getTenant, getUserName, orgSearch} from "@iblai/ibl-web-react-common";

export function getRecommendedCoursesAPI(searchTerm, callback) {
    api.iblaxdApiCallWrapper(
        (args) =>
            api.iblaxdaimentorapi.aiMentorOrgsUsersRecommendCoursesRetrieve(
                ...(args || [])
            ),
        getTenant(),
        getUserName(),
        {
            returnCourseData: true,
            searchTerms: searchTerm,
            includeLearnerSkills: false,
        }
    )((err, data, response) => filterRecommendedCourseData(data, callback));
}

function filterRecommendedCourseData(data, callback) {
    const recommendedCourses = [];
    if ( data?.courses && Array.isArray(data?.courses)){
        for (let i = 0; i < data.courses.length && i < 5; i++) {
            const course = data.courses[i];
            recommendedCourses.push(course);
        }

    }

    callback(recommendedCourses);
}


export function getDetailedPathwayData(pathwayID, username, callback) {
    const uuid = pathwayID;
    const data = {
        pathway_uuid: uuid,
        username: username,
        platform_key: getTenant(),

    };
    api.ibldmpathway.getPathway(data, function(data) {
        callback(data);
    });
}

export function pathwaySearchDataAPI(searchTerm, callback) {
    searchTerm = searchTerm.toLowerCase();
    if (searchTerm.length > 4) {
        const data =
            '?filter={"_index":"ibl-dm.public.dl_catalog_app_course"}&context=' +
            searchTerm;
        orgSearch(data, function(data) {
            callback(data?.results);
        });
    }
}

export function createPathway(data, callback, errorCallback=()=>{}) {
    api.ibldmpathway.createPathway(data, function(response) {
        callback(response);
    }, function(error) {
        errorCallback(error)
    });
}

export const fetchUserPathwaysData = (tenantKey, username, callback, errorCallback) => {
    const requestData = {
        username: username,
        platform_key: tenantKey,
    };
    api.ibledxpathway.getUserPathways(
        requestData,
        (data) => {
            callback(data || []);
        },
        (error) => {
            console.error('getUserPathwaysAPI: ', error);
            errorCallback(error);
        }
    );
};
