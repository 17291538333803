import React from 'react';
import EmptyTableDataInfographic from "../EmptyTableDataInfographic/EmptyTableDataInfographic";
import {getRamdomCourseImg} from "../../hooks/helpers";
import './PathwayDetailPopup.css'

const PathwayDetailPopup = ({pathway, setFocused}) => {
    return (
        <div react-component="InvitePopup" className="popup_div-nm_popup pathway-detail-popup" style={{display:"flex"}}>
            <div className="popup_cont-sk">
                <div className="hf_popup small">
                    <div className="div-block-147 new_msg">
                        <div className="text-block-135 mg-0">Pathway | {pathway?.name}</div>
                    </div>
                    <div className="div-block-228 pathway-content-block">
                        <div>{pathway?.data?.description}</div>
                        {
                            Array.isArray(pathway?.path) && pathway.path.length > 0 ? (
                                <div className="w-layout-grid pathway-content-grid less-pad">
                                    {
                                        pathway.path.map((course)=> (
                                            <a href="#" onClick={e=>e.preventDefault()} className="pathway-content-box w-inline-block">
                                                <div className="pathway-content-box-bg" style={{backgroundImage:`url(${getRamdomCourseImg()})`}}></div>
                                                <div className="w-layout-vflex pathway-content-box-detail-block">
                                                    <div>{course?.name}
                                                    </div>
                                                </div>
                                                {/*<div className="w-layout-hflex pathway-content-box-closer"><img
                                                    src="/images/x-5.svg" loading="lazy" alt="" className="image-149"/>
                                                </div>*/}
                                            </a>
                                        ))
                                    }

                                </div>
                            ) : (
                                <EmptyTableDataInfographic label={"No course content available"}/>
                            )
                        }

                    </div>
                    <div className="div-block-147 new_msg">
                        <div className="div-block-264 f-w">
                            <div onClick={() => setFocused(null)} data-w-id="be80af4b-9e75-153f-5ead-3ed42b6787c5"
                                 className="div-block-207 first active follow-user alt-btn cancel-invite">
                                <div className="text-block-111 red-text cf">Close</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PathwayDetailPopup;