import styled from 'styled-components';
import { actionTypes } from '../../views/content/InvitationsViewContent/InvitationsReducer';
import {
  getPlatformInvitations,
  isInvitationResponseError,
  postCreateOrRedeemInvitations,
} from '../../hooks/utils/invitations';
import { useTranslation } from 'react-i18next';
import './PopUp.css'

const PopUpContainer = styled.div`
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
`;

const handleConfirmBtn = (state, dispatch, handlePageChange) => {
  postCreateOrRedeemInvitations(state, (data) => {
    if (state.isRedeemActive) {
      dispatch({ type: actionTypes.TOGGLE_REDEEM });
      dispatch({
        type: actionTypes.SET_FIELD,
        field: 'userNameOrEmail',
        value: '',
      });
    } else {
      dispatch({ type: actionTypes.TOGGLE_INVITE_USER });
      dispatch({ type: actionTypes.RESET_INVITATION_STATE });
    }

    if (isInvitationResponseError(data)) {
      return;
    }

    getPlatformInvitations(
      (data) => {
        handlePageChange(1);
      },
      10,
      1
    );

    window.alert(
      'Your invitation has been successfully submitted or redeemed.'
    );
  });
};

const handleCancelBtn = (actionType, dispatch) => {
  dispatch({ type: actionTypes[actionType] });
  dispatch({ type: actionTypes.RESET_INVITATION_STATE });
};
export const PopUp = ({
  title,
  children,
  dispatch,
  isActive,
  actionType,
  state,
  handlePageChange,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`popup_div-nm_popup invite-popup ${isActive ? 'active' : ''}`}
    >
      <div className="popup_cont-sk">
        <div className="hf_popup">
          <div className="div-block-147 new_msg">
            <div className="text-block-135 mg-0">{title}</div>
          </div>
          <PopUpContainer>
            {children}
            <div className="div-block-147 new_msg">
              <div className="div-block-264 f-w">
                <div
                  className="div-block-207 first active follow-user alt-btn cancel-invite"
                  onClick={() => handleCancelBtn(actionType, dispatch)}
                >
                  <div className="text-block-111 red-text cf">
                    {t('Cancel')}
                  </div>
                </div>
                <div
                  className="div-block-207 first active follow-user redeem-btn mg-l16"
                  onClick={() => {
                    handleConfirmBtn(state, dispatch, handlePageChange);
                  }}
                >
                  <div className="text-block-111 red-text cf">
                    {t('Confirm')}
                  </div>
                </div>
              </div>
            </div>
          </PopUpContainer>
        </div>
      </div>
    </div>
  );
};
