import {useEffect, useState} from 'react';
import {addLicenseContent, getLicensesList} from "./utils/licensing";
import {useDebounce} from "./useDebounce";  // Replace with the correct path

const useLicensingContent = ({platformType="platform", search="", page=1, size=10}) => {
    const [licenses, setLicenses] = useState([]);
    const [licensesLoading, setLicensesLoading] = useState(false);

    const handleLicenseListRetrieval = (data) => {
        setLicenses(Array.isArray(data?.results) ? data.results : [])
        setLicensesLoading(false)
    }

    const handleCreateLicense = (data, callback) => {
        addLicenseContent(data, callback)
    }

    useDebounce(()=>{
        setLicensesLoading(true)
        getLicensesList(platformType, /*{
            length:size,
            page,
            ...(!!search ? {
                query:search
            } : {})
        }*/ {}, handleLicenseListRetrieval, handleLicenseListRetrieval)
    }, 200, [search, page, size])

    return {
        licenses,
        licensesLoading,
        handleCreateLicense
    }


};

export default useLicensingContent;
