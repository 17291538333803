import React, { useState } from 'react';

import './StudioAuthoringPathwaysViewContent.css';
import { Link } from 'react-router-dom';
import {
  getRamdomCourseImg,
  getStudioURL,
  getTableSizes,
} from '../../../hooks/helpers';
import TableSizeDropdown from '../../../components/TableSizeDropdown/TableSizeDropdown';
import { getUserName, IBLSpinLoader } from '@iblai/ibl-web-react-common';
import EmptyTableDataInfographic from '../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic';
import { useStudioAuthoringPrograms } from '../../../hooks/useStudioAuthoringPrograms';
import AddPathway from "../../../components/AddPathway/AddPathway";
import usePathways from "../../../hooks/usePathways";
import PathwayDetailPopup from "../../../components/PathwayDetailPopup/PathwayDetailPopup";

const TABLE_SIZES = getTableSizes();

const StudioAuthoringPathwaysViewContent = () => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(TABLE_SIZES[0]);
  const [refresher, setRefresher] = useState(null)
  const {filteredPathways, loading} = usePathways({search, refresher})
  const [addPathwayFocused, setAddPathwayFocused] = useState(false);
  const [focusedPathway, setFocusedPathway] = useState(null)

  return (
    <>
      <div className="div-block-183">
        <div className="div-block-205">
          <div className="div-block-206"></div>
          <div className="text-block-113">AUTHORING | PATHWAYS</div>
        </div>
        <div className="div-block-308">
          <a
            prop-events-value-onclick="handleAddNewCourseClick"
            prop-events-names="onClick"
            href="#"
            onClick={()=>setAddPathwayFocused(true)}
            className="link-block-22 w-inline-block"
          >
            <div className="div-block-207 first active follow-user">
              <div className="text-block-111">Add New Pathway</div>
            </div>
          </a>
        </div>
      </div>
      <div react-component="CourseTable" className="table_cont-2">
        <div className="div-block-217">
          {/*<div className="div-block-169">
            <div>
              <div
                prop-data-value-tablename="Programs"
                prop-data-names="tableName"
                className="text-block-135"
              >
                Programs
                <br />
              </div>
            </div>
          </div>*/}
          <div className="div-block-169">
            {/*<div className="div-block-171">
              <div className="sm">Show</div>
              <div className="form-block w-form">
                <TableSizeDropdown setSize={setSize} />
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
              <div className="sm">entries</div>
            </div>*/}
            <div className="div-block-171">
              <div className="sm">Search:</div>
              <div className="form-block w-form">
                <form
                  id="email-form-2"
                  name="email-form-2"
                  data-name="Email Form 2"
                  method="get"
                  data-wf-page-id="65830fc855f7d3a66ce3da0b"
                  data-wf-element-id="718b5728-a03b-be9a-4e5e-ebcb03ab51be"
                >
                  <input
                    onChange={(e) => setSearch(e?.target?.value)}
                    className="input w-input"
                    maxLength="256"
                    name="field-3"
                    data-name="Field 3"
                    placeholder=""
                    prop-events-value-onchange="handleSearchBarOnChange"
                    type="text"
                    id="field-3"
                    prop-events-names="onChange"
                    required=""
                  />
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div react-component="table" className="table">
            <div className="th">
              <div className="tc _10">
                <div className="sm">#</div>
              </div>
              <div className="tc _40">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Name</div>
                </div>
              </div>
              <div className="tc _25">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Visibility</div>
                </div>
              </div>
              <div className="tc _25">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Nbr of courses</div>
                </div>
              </div>
            </div>
            {loading ? (
              <IBLSpinLoader
                color={'#FFF'}
                size={20}
                containerHeight={'200px'}
              />
            ) : Array.isArray(filteredPathways) && filteredPathways.length > 0 ? (
                filteredPathways.map((pathway, index) => (
                <div
                  key={index}
                  react-component="AuthoringProgramsRow"
                  className="tr"
                >
                  <div className="tc tcell _10">
                    <div prop-rowindex="" className="m">
                      {index + 1}
                    </div>
                  </div>
                  <div onClick={()=>setFocusedPathway(pathway)} className="tc tcell _40">
                    <img
                      className="r-i"
                      src={getRamdomCourseImg()}
                      alt={pathway?.name}
                    />
                    <a
                        href={"#"}
                        onClick={e=>e.preventDefault()}

                      //to={`/studio/authoring/programs/${pathway?.data?.program_id}`}
                      className="tl-2 np"
                    >
                      <span prop-coursetitle="" className="nb">
                        {pathway?.name}
                      </span>
                    </a>
                  </div>
                  <div className="tc tcell _25">
                    <div prop-courseid="" className="sm">
                      {pathway?.visible ? "Public" : "Private"}
                    </div>
                  </div>
                  <div className="tc tcell w_a _25">
                    <div prop-programslug="" className="sm">
                      {Array.isArray(pathway?.path) ? pathway?.path.length : 0}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <EmptyTableDataInfographic />
            )}
            {/*<div react-component="AuthoringProgramsPagination" className="tf">
                            <div className="div-block-289">
                                <div prop-totalitems="" prop-currentpage="" prop-itemsperpage=""
                                     className="info-3">Showing 1 to 10 of 20 entries
                                </div>
                                <div className="pagination">
                                    <div prop-currentpage="" className="arrow-invite-previous-disabled p-item">
                                        <div className="text-block fa_icon fa-arrow disabled"></div>
                                    </div>
                                    <div prop-currentpage="" className="p-active p-item">
                                        <div>1</div>
                                    </div>
                                    <div prop-currentpage="" className="p-item">
                                        <div>2</div>
                                    </div>
                                    <div prop-totalitems="" prop-currentpage="" className="p-item arrow-next">
                                        <div className="text-block fa_icon fa-arrow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>*/}
          </div>
        </div>
      </div>
      {
        addPathwayFocused && <AddPathway setFocused={setAddPathwayFocused} setRefresher={setRefresher} />
      }
      {
        focusedPathway && <PathwayDetailPopup pathway={focusedPathway} setFocused={setFocusedPathway} />
      }

    </>
  );
};

export default StudioAuthoringPathwaysViewContent;
