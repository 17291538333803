import { useEffect, useRef, useState } from 'react';
import {getAdminProgramsAPI, getStudioURL, getTableSizes} from './helpers';
import {getCourseAPI} from "../views/content/SkillsCoursesViewContent/utils";

const studioURL = getStudioURL();
const TABLE_SIZES = getTableSizes();
export const useStudioAuthoringPrograms = (username) => {
  const hasFetchedData = useRef(false);
  const [profilePrograms, setProfilePrograms] = useState([]);
  const [profileProgramsLoaded, setProfileProgramsLoaded] = useState(false);

  const [limit, setLimit] = useState(TABLE_SIZES[0]);
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  const fetchPrograms = async () => {
    if (!hasFetchedData.current) {
      const response = await getCourseAPI(limit, 0, search, true);
      setProfilePrograms(Array.isArray(response?.results) ? response.results : []);
      setProfileProgramsLoaded(true);
      /*getAdminProgramsAPI(
        username,
        (programs) => {
          setProfilePrograms(programs);
          setProfileProgramsLoaded(true);
        },
        (programDetail) => {
          // Update the specific program's detail asynchronously
          setProfilePrograms((currentPrograms) => {
            return currentPrograms.map((program) => {
              if (program.program_id === programDetail.program_id) {
                return { ...program, ...programDetail };
              }
              return program;
            });
          });
        }
      );*/
      hasFetchedData.current = true;
    }
  }

  useEffect( () => {
    fetchPrograms()
  }, [limit]);

  return [profilePrograms, profileProgramsLoaded];
};
