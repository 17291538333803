import React, {useEffect, useState} from 'react';
import {fetchUserPathwaysData} from "./useStudioAuthoringPathways";
import {getTenant, getUserName} from "@iblai/ibl-web-react-common";
import {useDebounce} from "./useDebounce";

const usePathways = ({search="", refresher=null}) => {

    const [pathways, setPathways] = useState([])
    const [filteredPathways, setFilteredPathways] = useState([])
    const [loading, setLoading] = useState(false)

    const handleFetchPathways = () => {
        const userName = getUserName()
        const tenant = getTenant()
        setLoading(true)
        fetchUserPathwaysData(tenant, userName, (response)=>{
            const result = Array.isArray(response) ? response : []
            setPathways(result)
            setFilteredPathways(result)
            setLoading(false)
        }, ()=>{
            setPathways( [])
            setFilteredPathways( [])
            setLoading(false)
        })
    }

    const handlePathwaysSearch = () => {
        if(search.length > 1){
            setFilteredPathways(pathways.filter((pre)=>String(pre?.name).toUpperCase().includes(String(search).toUpperCase())))
        }else{
            setFilteredPathways(pathways)
        }
    }

    useDebounce(handlePathwaysSearch, 200, [search])


    useEffect(() => {
        handleFetchPathways()
    }, [refresher]);

    return {
        loading, filteredPathways
    }
};

export default usePathways;