import { useState, useEffect } from 'react';
import { getUserId, IBLSpinLoader } from '@iblai/ibl-web-react-common';

const IssueCredential = ({
  notification,
  setNotification,
  loading,
  setLoading,
  setAssertionPopupOpen,
  courseID,
  entityID,
  setRefreshAssertions,
}) => {
  const [username, setUsername] = useState('');

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

 const postIssueCredential = async (data) => {
   const url = `${process.env.REACT_APP_IBL_DM_URL}/api/credentials/orgs/main/users/${getUserId()}/${entityID}/assertions/`;

   try {
     const response = await fetch(url, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         Authorization: `Token ${localStorage.getItem('axd_token')}`,
       },
       body: JSON.stringify(data),
     });

     if (!response.ok) {
       const errorData = await response.json();
       // Error handling block
       setNotification({
         success: false,
         msg: errorData.message || 'Something went wrong, please try again.',
       });
  
       throw new Error(errorData.detail || 'Failed to issue credential');
     }

     // Success block
     setRefreshAssertions((prev) => !prev);
     setUsername('');
     setNotification({
       success: true,
       msg: 'Credential issued successfully',
     });
   } catch (err) {
     // Catch block to handle any other errors
     setNotification({
       success: false,
       msg: err.message || 'Something went wrong, please try again.',
     });
   } finally {
     setLoading(false);
   }
 };


  const handleSubmit = () => {
    if (!username || username.trim() === '') {
      setNotification({
        success: false,
        msg: 'All fields must be filled and not blank.',
      });
      return;
    }
    setLoading(true);
    postIssueCredential({
      recipient: {
        identity: username,
      },
      course: courseID,
      metadata: {},
    });
  };

  useEffect(() => {
    // Clear notification when any input changes
    if (username) {
      setNotification({ success: null, msg: null });
    }
  }, [username]);

  return (
    <div
      style={{ display: 'flex' }}
      react-component="InvitePopup"
      className="popup_div-nm_popup assign-license-popup"
    >
      <div className="popup_cont-sk">
        <div className="hf_popup">
          <div className="div-block-147 new_msg">
            <div className="text-block-135 mg-0">Issue Credential to User</div>
            {notification?.msg && (
              <div
                className={`license-notification-block ${!notification?.success ? 'error' : ''}`}
              >
                {notification?.msg}
              </div>
            )}
          </div>
          <div
            data-current="Platform"
            data-easing="ease"
            data-duration-in="300"
            data-duration-out="100"
            className="tabs w-tabs"
          >
            <div className="w-tab-content">
              <div data-w-tab="Platform" className="w-tab-pane w--tab-active">
                <div className="div-block-228 less-pad">
                  <div className="form-block-7 w-form">
                    <form
                      id="email-form-5"
                      name="email-form-5"
                      data-name="Email Form 5"
                      method="get"
                      data-wf-page-id="66e0598d95b3c617324f4850"
                      data-wf-element-id="04b212b6-6908-556e-c3c3-d1f2508be1ea"
                    >
                      <label
                        htmlFor="field-5"
                        className="text-block-139-bd-f-soc"
                      >
                        Course
                      </label>
                      <input
                        className="text-field-3 w-input"
                        name="courseId"
                        data-name="Course ID"
                        placeholder="Enter the Course ID"
                        type="text"
                        id="course-id"
                        style={{ backgroundColor: 'transparent' }}
                        value={courseID}
                        readOnly
                      />
                      <label
                        htmlFor="field-5"
                        className="text-block-139-bd-f-soc"
                      >
                        Credential ID
                      </label>
                      <input
                        className="text-field-3 w-input"
                        name="credential"
                        data-name="credential"
                        placeholder="Enter the Credential ID"
                        type="text"
                        id="credential"
                        style={{ backgroundColor: 'transparent' }}
                        value={entityID}
                        readOnly
                      />
                      <label
                        htmlFor="field-5"
                        className="text-block-139-bd-f-soc"
                      >
                        Username
                      </label>
                      <input
                        className="text-field-3 w-input"
                        name="username"
                        data-name="username"
                        placeholder="Enter username"
                        type="text"
                        id="username"
                        value={username}
                        onChange={handleUsernameChange}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-block-147 new_msg">
            <div className="div-block-264 f-w">
              <div
                onClick={() => setAssertionPopupOpen(false)}
                data-w-id="04b212b6-6908-556e-c3c3-d1f2508be27f"
                className="div-block-207 first active follow-user alt-btn cancel-invite"
              >
                <div className="text-block-111 red-text cf">Close</div>
              </div>
              <div
                onClick={handleSubmit}
                className="div-block-207 first active follow-user redeem-btn mg-l16"
              >
                <div className="text-block-111 red-text cf">Issue</div>
                {loading && (
                  <span style={{ marginLeft: '10px' }}>
                    <IBLSpinLoader size={15} color={'#FFF'} />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IssueCredential;
