import React, {useRef, useState} from 'react';
import './AddPathway.css'
import {createPathway, getRecommendedCoursesAPI} from "../../hooks/useStudioAuthoringPathways";
import {useDebounce} from "../../hooks/useDebounce";
import {getTenant, getUserName, IBLSpinLoader, NoItemView} from "@iblai/ibl-web-react-common";
import Async from "react-select/async";
import EmptyTableDataInfographic from "../EmptyTableDataInfographic/EmptyTableDataInfographic";
import {getRamdomCourseImg, slugify} from "../../hooks/helpers";
import {getCourseAPI} from "../../views/content/SkillsCoursesViewContent/utils";

const AddPathway = ({
    setFocused=()=>{}, setRefresher=()=>{}
                    }) => {

    const defaultFields = {
        name:"",
        description:"",
        subject:"",
        visibility:false,
    }
    const [fields, setFields] = useState(defaultFields)
    const [courseLoading, setCourseLoading] = useState(false)
    const [selectedCourses, setSelectedCourses] = useState([])
    const [addContentFocused, setAddContentFocused] = useState(false)
    const [selectValue, setSelectValue] = useState(null)
    const [loading, setLoading] = useState(false)
    const [notification, setNotification] = useState({
        success:null,
        msg:null
    })

    const handleSetNotification = (success, msg, callback=()=>{}) => {
        setNotification({
            msg: msg,
            success
        })
        setTimeout(()=>{
            setNotification({})
            callback()
        }, 2500)
    }

    const handleRetrieveFromSelectedCourse = (courseID) => {
        setSelectedCourses(selectedCourses.filter((pre)=>pre.course_id!==courseID))
    }

    const handleCourseContentChange = (choice) => {
        if(choice && selectedCourses.findIndex((pre)=>pre.course_id === choice.value)===-1){
            setSelectedCourses([
                {
                    course_id:choice?.value,
                    name:choice?.label,
                    item_type:"course",
                    img:getRamdomCourseImg()
                },
                ...selectedCourses,
            ])
            //reactSelectRef.current.clearValue();
            setSelectValue(null)
        }
    }

    const handleLoadRecommendedCourses = async () => {
        if(!fields.name){
            setSelectedCourses([])
            return
        }
        setCourseLoading(true)
        const fetchedCourses = await getCourseAPI(6, "", fields.name, false, false)
        setCourseLoading(false)
        if(!Array.isArray(fetchedCourses?.results)){
            setSelectedCourses([])
            return
        }
        setSelectedCourses(fetchedCourses?.results.filter((pre)=>(pre?.data?.course_id && pre?.data?.name)).map((course)=> (
            {
                course_id:course?.data?.course_id,
                name:course?.data?.name,
                item_type:"course",
                img:getRamdomCourseImg()
            }
        )))
        /*getRecommendedCoursesAPI(fields.name, (courses)=>{
            setCourseLoading(false)
            if(!Array.isArray(courses)){
                setSelectedCourses([])
                return
            }
            setSelectedCourses(courses.filter((pre)=>(pre?.course_id && pre?.name)).map((course)=> (
                {
                    course_id:course?.course_id,
                    name:course?.name,
                    item_type:"course",
                    img:getRamdomCourseImg()
                }
            )))
        })*/
    }

    const coursesPromiseOptions = (inputValue) => {
        return new Promise(async (resolve)=>{
            if(!inputValue){
                resolve([])
            }
            const fetchedCourses = await getCourseAPI(12, "", inputValue, false, false)
            if(!Array.isArray(fetchedCourses?.results)){
                resolve([])
            }else{
                resolve(fetchedCourses?.results.map((course)=> (
                    {
                        value:course?.data?.course_id,
                        label:course?.data?.name,
                    }
                )))
            }
        })
    }

    const handleInputChange = (e) => {
        setFields({
            ...fields,
            [e?.target?.name]:e?.target?.value
        })
    }

    const handleCreatePathway = () => {
        setLoading(true)
        const data = {
            pathway_id:`${getTenant()}_${slugify(fields?.name)}_${Date.now()}`,
            username:getUserName(),
            name:fields?.name,
            path:selectedCourses.map((course)=>({
                item_type:course.item_type,
                course_id:course.course_id
            })),
            visible:fields.visibility==="1",
            data:{
                description: fields.description,
                subject: fields.subject
            }
        }
        createPathway(JSON.stringify(data), (response)=>{
            setLoading(false)
            setSelectedCourses([])
            setFields(defaultFields)
            setRefresher(Date.now())
            handleSetNotification(true, "Pathway created successfully.", ()=>{
                setFocused(false)
            })
        }, ()=>{
            setLoading(false)
            handleSetNotification(false, "An error occurred. Please try again.")
        })
    }

    useDebounce(handleLoadRecommendedCourses, 200, [fields.name])

    return (
        <>
            <div react-component="InvitePopup" className="popup_div-nm_popup add-pathway-popup"
                 style={{display: "flex"}}>
                <div className="popup_cont-sk">
                    <div className="hf_popup big">
                        <div className="div-block-147 new_msg j-content-space-btw">
                            <div className="text-block-135 mg-0">Add new pathway</div>
                            {
                                notification?.msg && (
                                    <div className={`license-notification-block ${!notification?.success ? "error" : ""}`}>
                                        {notification?.msg}
                                    </div>
                                )
                            }
                        </div>
                        <div className="div-block-228 pathway-feature">
                            <div className="form-block-7 w-form">
                                <form id="email-form-5" name="email-form-5" data-name="Email Form 5" method="get"
                                      data-wf-page-id="6728ea4bd0fbb80d32599ac2"
                                      data-wf-element-id="69c3f7d9-f5ca-e5cf-df47-778e525ded37">
                                    <div><label htmlFor="field-6" className="text-block-139-bd-f-soc">Name *</label>
                                        <input
                                            className="text-field-11 w-input" maxLength="256" data-name="Field 6"
                                            placeholder="" type="text" id="field-6" required=""
                                            name="name"
                                            onChange={handleInputChange}
                                            value={fields?.name}
                                        /></div>
                                    <div><label htmlFor="field-7" className="text-block-139-bd-f-soc">Description
                                        *</label><textarea placeholder="" maxLength="5000" id="field-7"
                                                           data-name="Field 7" className="textarea-2 w-input"
                                                           name="description"
                                                           onChange={handleInputChange}
                                                           value={fields?.description}
                                    ></textarea>
                                    </div>
                                    <div><label htmlFor="field-8" className="text-block-139-bd-f-soc">Subject
                                        *</label><input className="text-field-11 w-input" maxLength="256"
                                                        data-name="Field 6" placeholder="" type="text" id="field-6"
                                                        required=""
                                                        name="subject"
                                                        onChange={handleInputChange}
                                                        value={fields?.subject}
                                    /></div>
                                    <div><label htmlFor="field-8" className="text-block-139-bd-f-soc">Visibility
                                        *</label><select onChange={handleInputChange} id="field-8" name="visibility"
                                                         data-name="Field 8"
                                                         className="select-field-14 w-select">
                                        <option selected={fields.visibility==="0"} value={"0"}>Private</option>
                                        <option selected={fields.visibility==="1"} value={"1"}>Public</option>
                                    </select></div>
                                </form>
                                <div className="w-form-done">
                                    <div>Thank you! Your submission has been received!</div>
                                </div>
                                <div className="w-form-fail">
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            </div>
                            <div
                                className={`w-layout-vflex flex-block-22 ${selectedCourses.length === 0 ? "no-pathway-course-content" : ""}`}>
                                {
                                    courseLoading && <IBLSpinLoader size={20} color={"#FFF"} containerHeight={"100px"}/>
                                }
                                {
                                    selectedCourses.length > 0 && (
                                        <div className="w-layout-grid pathway-content-grid">
                                            {
                                                selectedCourses.map((course, index) => (
                                                    <a key={`pathway-course-${index}`} href="#"
                                                       onClick={e => e.preventDefault()}
                                                       className="pathway-content-box w-inline-block">
                                                        <div style={{backgroundImage:`url(${course?.img})`}} className="pathway-content-box-bg"></div>
                                                        <div className="w-layout-vflex pathway-content-box-detail-block">
                                                            <div>{course.name}</div>
                                                        </div>
                                                        <div
                                                            onClick={() => handleRetrieveFromSelectedCourse(course.course_id)}
                                                            className="w-layout-hflex pathway-content-box-closer"><img
                                                            src="/images/x-5.svg"
                                                            loading="lazy"
                                                            alt=""
                                                            className="image-149"/>
                                                        </div>
                                                    </a>
                                                ))
                                            }
                                        </div>
                                    )
                                }
                                <div onClick={()=>setAddContentFocused(true)} data-w-id="7b442005-5917-fd43-a7d3-47a0c2059437"
                                     className="w-layout-hflex pathway-add-content-btn"><img src="/images/plus-1.svg"
                                                                                             loading="lazy" alt=""
                                                                                             className="image-148"/>
                                    <div className="text-block-184">Add content</div>
                                </div>
                            </div>
                        </div>
                        <div className="div-block-147 new_msg border-top-only">
                            <div className="div-block-264 f-w">
                                <div onClick={() => setFocused(false)} data-w-id="705ec0e6-b838-5221-806c-413bd91b447b"
                                     className="div-block-207 first active follow-user alt-btn cancel-invite">
                                    <div className="text-block-111 red-text cf">Cancel</div>
                                </div>
                                <div onClick={!loading ? ()=>handleCreatePathway() : ()=>{}} className="div-block-207 first active follow-user redeem-btn mg-l16">
                                    <div className="text-block-111 red-text cf">Add </div> {loading &&
                                    <span style={{marginLeft: "15px"}}><IBLSpinLoader size={15}
                                                                                      color={"#FFF"}/> </span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                addContentFocused && (
                    <div react-component="InvitePopup" style={{display:"flex"}} className="popup_div-nm_popup pathway-add-content-popup">
                        <div className="popup_cont-sk">
                            <div className="hf_popup small">
                                <div className="div-block-147 new_msg">
                                    <div className="text-block-135 mg-0">Add content</div>
                                </div>
                                <div className="div-block-228 pathway-content-block">
                                    <div className="form-block-7 w-form">
                                        <form id="email-form-5" name="email-form-5" data-name="Email Form 5" method="get"
                                              data-wf-page-id="6728ea4bd0fbb80d32599ac2"
                                              data-wf-element-id="039bc8dd-caec-6a66-795b-ccb7363e1a09">
                                            <Async placeholder={"Search for a course..."}
                                                   cacheOptions
                                                   classNamePrefix={"react-select"}
                                                   isClearable
                                                   //defaultOptions
                                                   loadOptions={coursesPromiseOptions}
                                                   onChange={handleCourseContentChange}
                                                   value={selectValue}
                                            />
                                            {/*<div className="w-layout-hflex flex-block-14"><img src="images/search-1.svg"
                                                                                               loading="lazy" alt=""
                                                                                               className="image-143"/><input
                                                className="text-field-10 w-input" maxLength="256" name="field-6"
                                                data-name="Field 6" placeholder="Search content" type="text" id="field-6"/>
                                            </div>*/}
                                        </form>
                                        <div className="w-form-done">
                                            <div>Thank you! Your submission has been received!</div>
                                        </div>
                                        <div className="w-form-fail">
                                            <div>Oops! Something went wrong while submitting the form.</div>
                                        </div>
                                    </div>
                                    {
                                        selectedCourses.length > 0 ?
                                            (
                                                <div className="w-layout-grid pathway-content-grid less-pad">
                                                    {
                                                        selectedCourses.map((course)=> (
                                                            <a href="#" onClick={e=>e.preventDefault()} className="pathway-content-box w-inline-block">
                                                                <div style={{backgroundImage:`url(${course?.img})`}} className="pathway-content-box-bg"></div>
                                                                <div
                                                                    className="w-layout-vflex pathway-content-box-detail-block">
                                                                    <div>{course?.name}</div>
                                                                </div>
                                                                <div onClick={()=>handleRetrieveFromSelectedCourse(course?.course_id)}
                                                                    className="w-layout-hflex pathway-content-box-closer">
                                                                    <img
                                                                        src="/images/x-5.svg"
                                                                        loading="lazy"
                                                                        alt=""
                                                                        className="image-149"/>
                                                                </div>
                                                            </a>
                                                        ))
                                                    }
                                                </div>
                                            )
                                            :
                                            <EmptyTableDataInfographic label={"No course selected"} />
                                    }

                                </div>
                                <div className="div-block-147 new_msg">
                                    <div className="div-block-264 f-w">
                                        <div onClick={()=>setAddContentFocused(false)} data-w-id="43ab3825-2d73-71e4-86f6-ae18debd1b63"
                                             className="div-block-207 first active follow-user alt-btn cancel-invite">
                                            <div className="text-block-111 red-text cf">Close</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default AddPathway;