import { useParams } from "react-router-dom";
import AssertionsTable from "../Tables/AssertionsTable/AssertionsTable";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const AssertionsViewContent = () => {
  const { courseID, entityID } = useParams();
    const [assertionPopupOpen, setAssertionPopupOpen] = useState(false);


  return (
    <>
      <div className="div-block-183">
        <div className="div-block-205">
          <div className="div-block-206" />
          <div
            className="text-block-113"
            style={{ textTransform: 'capitalize' }}
          >
            ASSERTION <span> | {courseID}</span>
          </div>
        </div>
        <div className="div-block-308">
          <NavLink
            prop-events-names="onClick"
            href="#"
            className="link-block-22 w-inline-block"
            onClick={() => setAssertionPopupOpen(true)}
          
          >
            <div className="div-block-207 first active follow-user">
              <div className="text-block-111">Issue Assertion</div>
            </div>
          </NavLink>
        </div>
      </div>
      <AssertionsTable entityId={entityID} courseID={courseID} setAssertionPopupOpen={setAssertionPopupOpen} assertionPopupOpen={assertionPopupOpen} />
    </>
  );
};

export default AssertionsViewContent;
